import React from "react";
import Layout from "../components/Layout";
import ColinTheComparingPig from "../../svg/about-the-comparison-company/colinthecomparingpig.svg";
import CompareIcon from "../../svg/about-the-comparison-company/compare.svg";
import AutomateBillPaymentIcon from "../../svg/about-the-comparison-company/automatebillpaying.svg";
import EarnMoneyIcon from "../../svg/about-the-comparison-company/earnmoney.svg";
import RankingIcon from "../../svg/about-the-comparison-company/ranking.svg";
import SaveTimeIcon from "../../svg/about-the-comparison-company/savetime.svg";
import SwitchAutomaticallyIcon from "../../svg/about-the-comparison-company/switchautomatically.svg";
import TotalPrivacyIcon from "../../svg/about-the-comparison-company/totalprivacy.svg";
import TrackUtilityIcon from "../../svg/about-the-comparison-company/trackutility.svg";
import MissionStatementSection from "../components/MissionStatementSection";
import TCCOutboundLink from "../components/TCCOutboundLink";

const Index = () => (
  <Layout pageDescription="Find An Electricity Rate Thats Right For You">
    <section className="">
      <div className="container flex flex-col items-center px-5 py-4 mx-auto lg:py-10 md:flex-row">
        <div className="flex flex-col items-center mb-4 text-center lg:flex-grow md:w-1/2 lg:pr-4 md:pr-16 md:items-start md:text-left lg:mb-10 md:mb-0">
          <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
            <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-3/4 xl:my-px xl:px-px xl:w-3/4">
              <h1 className="">About us</h1>
              <h3 className="text-blue-500">What is The Comparison Company</h3>
              <p className="">
                We started The Comparison Company to offer consumers a genuinely ethical and transparent way of navigating the minefield that is financial services. Our goal is to help even the most
                novice consumers understand what options are available so they can make smart, informed decisions regarding their selection of utility providers, bank accounts, insurance products,
                loans, and much more.
              </p>

              <h3 className="text-blue-500">Why now?</h3>
              <p>
                According to{" "}
                <TCCOutboundLink href="https://www.npr.org/2018/09/19/649633468/31-percent-of-u-s-households-have-trouble-paying-energy-bills?t=1597319004075" title="NPR">
                  NPR
                </TCCOutboundLink>
                , nearly a third of households in the US have struggled to pay their energy bills, with some even forgoing food, medicine, or other necessities to pay their bills.
              </p>

              <p>
                When it comes to basic home utility costs like electricity, water, and natural gas, the average American household can spend anywhere from $2,200 to $4,000 a year—a significant chunk
                out of any family's budget. It's also no secret that Americans in major cities find themselves strapped with excessive monthly costs for these and additional services - almost{" "}
                <TCCOutboundLink href="https://www.cnbc.com/2019/04/30/americans-in-cities-spend-984-on-monthly-billsheres-how-to-pay-less.html" title="A thousand dollars">
                  $1,000 
                </TCCOutboundLink>{" "}
                per month!
              </p>
            </div>
            <div className="w-full px-px pb-8 my-px overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/4 xl:my-px xl:px-px xl:w-1/4">
              <ColinTheComparingPig className="h-56 ml-auto" />
            </div>
          </div>
          <p className="">
            We understand that in this Post COVID world your safety and financial well being is important. We are passionate about making a positive impact in your life by helping you save money and
            time by allowing you to compare, buy &amp; track your bills from the comfort of your home with the assurance that your information is kept private at all times.
          </p>
        </div>
      </div>
    </section>
    <section className="bg-blue-100 border border-blue-100 body-font">
      <div className="container flex flex-col items-center px-5 py-10 mx-auto bg-blue-100 border border-blue-100 md:flex-row">
        <div className="flex flex-col items-center mb-4 text-center lg:flex-grow md:w-1/2 lg:pr-4 md:pr-16 md:items-start md:text-left lg:mb-10 md:mb-0">
          <h2 className="mb-4 text-3xl font-medium text-gray-900 title-font sm:text-4xl">Our Benefits</h2>
          <div className="flex flex-wrap overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-1">
            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <CompareIcon className="inline-flex w-4 mr-4" />
                We do the comparing
              </h3>
              <p className="ml-8">We find the top 5 suppliers for each sector to only show the best</p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <SaveTimeIcon className="inline-flex w-4 mr-4" />
                Save time
              </h3>
              <p className="ml-8">Sign up to new services in under 5 mins &amp; never enter your data again </p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <TotalPrivacyIcon className="inline-flex w-4 mr-4" />
                Total privacy - Stop spam
              </h3>
              <p className="ml-8">We mask your contact information to stop robocalls &amp; spam completely </p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <EarnMoneyIcon className="inline-flex w-4 mr-4" />
                Earn money
              </h3>
              <p className="ml-8">Earn money towards bills by sharing prices with your friends &amp; family</p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <TrackUtilityIcon className="inline-flex w-4 mr-4" />
                Track utility use &amp; cost
              </h3>
              <p className="ml-8">Connect all bills into one simple dashboard &amp; see how to save money</p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <RankingIcon className="inline-flex w-4 mr-4" />
                Know how you rank
              </h3>
              <p className="ml-8">Learn your TCC Score &amp; see your costs compared to your neighbors</p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <AutomateBillPaymentIcon className="inline-flex w-4 mr-4" />
                Automate bill paying
              </h3>
              <p className="ml-8">Simplify payments, see your real costs</p>
            </div>

            <div className="w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
              <h3>
                <SwitchAutomaticallyIcon className="inline-flex w-4 mr-4" />
                Switch automatically
              </h3>
              <p className="ml-8">Where available, automatically switch to the lowest price each month</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="">
      <MissionStatementSection />
    </section>
  </Layout>
);

export default Index;
